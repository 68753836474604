






















































































import { Component, Vue, Ref } from 'vue-property-decorator';
import { Input, Icon } from '@h3/antd-vue';
import { WorkPlatformService } from '@WorkPlatform/services';

import { namespace } from 'vuex-class';

const workPlatformModule = namespace('workPlatform');

@Component({
  name: 'WorkPlatform',
  components: {
    TodoList: () => import('./todo-list.vue'),
    AppList: () => import('./app-list.vue'),
    RecentList: () => import('./recent-list.vue'),
    AInputSearch: Input.Search,
    AIcon: Icon,
  },
})
export default class WorkPlatform extends Vue {
  @workPlatformModule.Mutation('resetWorkPlatform') resetWorkPlatform;

  tabKey: number = 0;

  onlineAppList = [];

  tabs: any[] = [
    { name: '待办', key: 0, list: [], total: 0 },
    { name: '待阅', key: 1, list: [], total: 0 },
  ];

  badgeCount (count) {
    return count > 99 ? '99+' : count === 0 ? '' : count;
  }

  @Ref('appList') appList: any;

  getAllApps (apps) {
    this.onlineAppList = apps;
  }

  calcHeight () {
    this.$nextTick(() => {
      this.calcAppContainerHeight();
    });
  }

  calcAppContainerHeight () {
    this.setContainerCenter();
    const $commonUserAppCtn = document.querySelector('.recently-used') as any;
    const $borderTop = document.querySelector('.border-top') as any;
    const $wrapperCtn = document.querySelector('.platform-main') as any;
    const $appListCtn = document.querySelector('.app-wrapper') as any;
    $appListCtn.style.minHeight =
      $wrapperCtn.clientHeight -
      $commonUserAppCtn.clientHeight -
      $borderTop.clientHeight * 2 -
      2 +
      'px';
  }

  setContainerCenter () {
    const $leftCtn = document.querySelector('.todo-wrapper') as any;
    const marginRight = parseInt(
      window.getComputedStyle($leftCtn)?.marginRight, 10,
    );
    const screenWidth = document.documentElement?.clientWidth || 0;
    const leftCtnWidth = $leftCtn.clientWidth;
    const rightCtnWidth = (document.querySelector('.recently-used') as any)
      .clientWidth;
    $leftCtn.style.marginLeft =
      Math.max(((screenWidth - leftCtnWidth - rightCtnWidth - marginRight) as any) / 2, 0) +
      'px';
  }

  beforeDestroy () {
    this.resetWorkPlatform();
    window.removeEventListener('resize', this.calcAppContainerHeight);
    window.removeEventListener('message', this.reloadPage);
  }

  async created () {
    this.getTabs();
    window.addEventListener('message', this.reloadPage, false);
    window.addEventListener('resize', this.calcAppContainerHeight);
  }

  /**
   * 如果流程发生变化，则刷新数据
   */
  async reloadPage (evt: any) {
    const isFreeLogin: any = localStorage.IS_FREELOGIN;
    if (
      evt.data === 'reload' &&
      evt.origin === window.location.origin &&
      !isFreeLogin
    ) {
      this.getTabs();
    }
  }

  toggleTab (key: number) {
    this.tabKey = key;
  }

  /**
   * 获取待办待阅
   */
  getTabs () {
    Promise.all([this.getTodoList(), this.getToreadList()]).then((res: any) => {
      if (Array.isArray(res)) {
        res.forEach((item: any, index: number) => {
          const temp = item.data || [];
          this.tabs[index].list = temp.splice(0, 4); // 最多只展示4条数据
          this.tabs[index].total = item.total || 0;
        });
      }
    });
  }

  /**
   * 获取待办
   */
  getTodoList () {
    const params: any = {
      todoType: '1',
      page: 0,
      size: 4,
    };
    return WorkPlatformService.getTodoList(params).then((res: any) => {
      if (res.success) {
        return res;
      } else {
        return {};
      }
    });
  }

  /**
   * 获取待阅
   */
  getToreadList () {
    const params: any = {
      todoType: '2',
      page: 0,
      size: 3,
    };
    return WorkPlatformService.getTodoList(params).then((res: any) => {
      if (res.success) {
        return res;
      } else {
        return {};
      }
    });
  }

  /**
   * 搜索应用
   */
  appSearch (key: any) {
    console.log('key', key);
    this.appList.search(key);
    this.calcAppContainerHeight();
  }

  appChange (e: any) {
    this.appList.search(e.target.value);
    this.calcAppContainerHeight();
  }

  goPage () {
    this.$router.push({
      name: 'flow-center',
      query: {
        todoType: '1',
      },
    });
  }

  showMore () {
    this.$router.push({
      name: 'work-platform-more',
    });
  }
}
